import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-scroll';

import Seo from '../../components/Seo';
import Footer from '../../components/Footer';

import LoadingScreen from '../../components/LoadingScreen';
import FooterBottom from '../../components/FooterBottom';

import './styled.css';

import { Context } from '../../StoreContext/Context';

const PrivacyPolicy = () => {
    const { loading, loadEvent, info } = useContext(Context);
    const { eventKey } = useParams();

    useEffect(() => {
        loadEvent(eventKey);
    }, []);

    return (
        <>
            <Seo />
            {loading === false ? (
                <div>
                    <div id="wrapper">
                        <div className="header-politica">
                            <Link to={`/${eventKey}`}>
                                <img
                                    className="logo w-75"
                                    src={info?.logo}
                                    alt=""
                                />
                            </Link>

                            <Link to="/novalp" smooth duration={1000}>
                                Início
                            </Link>
                        </div>

                        <div id="content" className="no-bottom no-top">
                            <div className="container ">
                                <div className="text-terms">
                                    <h1>POLÍTICA DE PRIVACIDADE</h1>
                                    <h2>1. Da Introdução</h2>
                                    <p>
                                        A <b>RSTCOM</b>, por meio do
                                        <b> Website de Transmissão Ao Vivo</b>,
                                        deseja proporcionar a melhor experiência
                                        possível para garantir que você desfrute
                                        ao máximo de nossos serviços. A sua
                                        privacidade, bem como segurança dos seus
                                        dados pessoais são e serão sempre de
                                        extrema importância para nós. Assim,
                                        gostaríamos de demonstrar, de forma
                                        transparente, como e porque coletamos,
                                        armazenamos e utilizamos os seus dados
                                        pessoais.
                                    </p>
                                    <p>
                                        Desta forma, por meio da presente{' '}
                                        <b>
                                            olítica de Privacidade (“Política”)
                                        </b>{' '}
                                        serão fornecidos todos os
                                        esclarecimentos e detalhes necessários,
                                        portanto recomendamos que esta Política
                                        seja lida com atenção e ao acessar nosso{' '}
                                        <b>Website de Transmissão Ao Vivo</b>,
                                        você reconhece os termos e condições e
                                        concorda estar vinculado às suas
                                        condições de forma livre e espontânea.
                                        Ao usar este
                                        <b>Website de Transmissão Ao Vivo</b>,
                                        você autoriza a coleta e uso de
                                        informações conforme estabelecido nesta{' '}
                                        <b>Política de Privacidade.</b>
                                    </p>
                                    <h2>2. Sobre a Política</h2>
                                    <p>
                                        Esta <b>Política</b> define os detalhes
                                        essenciais relativos à sua lista de
                                        dados pessoais junto a <b>RSTCOM</b>,
                                        assim sendo, ocasionalmente poderemos
                                        desenvolver novos serviços ou oferecer
                                        serviços adicionais. Caso a introdução
                                        de tais serviços novos ou adicionais
                                        resulte em qualquer alteração relevante
                                        na forma na qual coletamos ou
                                        processamos seus dados pessoais,
                                        forneceremos a você informações,
                                        condições ou políticas adicionais.
                                        Exceto como de outra forma indicado ao
                                        serem introduzidos serviços novos ou
                                        adicionais, os mesmos estarão sujeitos à
                                        presente <b>Política</b>.
                                    </p>
                                    <p>
                                        O objetivo da presente <b>Política</b>{' '}
                                        contempla:
                                    </p>
                                    <ul>
                                        <li>
                                            Garantir que você compreenda as
                                            razões pelas quais coletamos e
                                            utilizamos os seus dados pessoais e
                                            de que forma são compartilhados.
                                        </li>
                                        <li>
                                            Explicar a forma na qual utilizamos
                                            os seus dados pessoais, com o fim de
                                            proporcionar a você uma excelente
                                            experiência de uso do Serviço de
                                            Transmissão Ao Vivo.
                                        </li>
                                        <li>
                                            Fornecer informações importantes
                                            relativas a todos identificadores
                                            eletrônicos, entre outros.
                                        </li>
                                    </ul>
                                    <p>
                                        Esperamos que o exposto ajude a
                                        compreender os nossos compromissos de
                                        privacidade para com os seus dados
                                        pessoais. Para maiores esclarecimentos
                                        sobre os termos utilizados na presente
                                        Política, entre em contato conosco
                                        através do e-mail contato@rstcom.com.br.
                                    </p>
                                    <h2>3. Do Tipo de Informações Coletadas</h2>
                                    <p>
                                        A <b>RSTCOM</b> é apenas fornecedora do
                                        <b>
                                            {' '}
                                            Website de Transmissão Ao vivo{' '}
                                        </b>{' '}
                                        da{' '}
                                        <b>
                                            Agência Origami no Evento Prêmio
                                            Mulheres que Transformam{' '}
                                        </b>
                                        e armazena seus dados pessoais, assim
                                        como dados de utilização, comentários,
                                        no prazo especificado no item 4 desta
                                        Política. Estes são usados para criação
                                        de estatísticas, gráficos, relatórios e
                                        informações suficientes para que o mesmo
                                        possa desenvolver estudos, matéria,
                                        conteúdo, pesquisa e entre outros.
                                    </p>
                                    <p>
                                        A <b>RSTCOM</b> por meio de seu
                                        <b> Website de Transmissão Ao vivo</b>,
                                        coleta as informações voluntariamente
                                        fornecidas por você ao realizar seu
                                        cadastro e também outras decorrentes de
                                        sua utilização do nosso{' '}
                                        <b> Website de Transmissão Ao vivo</b>.
                                        Da mesma forma que você interage por
                                        recurso ou ferramenta do
                                        <b>Website</b>, tais atividades poderão
                                        ser mantidas em nosso sistema. O sistema
                                        também registra informações tais como
                                        hora, tipo de navegador, linguagem do
                                        navegador, endereço de Protocolo de
                                        Internet (IP) em cada acesso e a forma
                                        como o Usuário utiliza o <b>Website</b>.
                                    </p>
                                    <p>
                                        Também serão armazenadas informações
                                        pessoais que sejam fornecidas por você
                                        dentro do escopo de suas atividades e
                                        interação com o <b>Website</b>. Tais
                                        informações armazenadas poderão ser
                                        fornecidas à{' '}
                                        <b>
                                            Agência Origami no Evento Prêmio
                                            Mulheres que Transformam
                                        </b>
                                        , tais como: Relatórios estatísticos
                                        sobre o acesso, chats, pesquisas,
                                        dúvidas e posteriormente destruída de
                                        nossos servidores, de acordo com o
                                        mensurado no item 4 desta
                                        <b>Política</b>.
                                    </p>
                                    <h2>4. Como usaremos a Informação</h2>
                                    <p>
                                        Algumas das informações acima
                                        mencionadas são armazenadas com o
                                        objetivo exclusivo de (i) caracterizar
                                        as suas preferências pessoais para
                                        aprimorar e customizar a sua experiência
                                        no uso do <b>Website</b>; (ii)
                                        contatá-lo diretamente ou por meio de
                                        parceiros comerciais da <b>RSTCOM</b>,
                                        para comunicar sobre alterações das
                                        ferramentas e funcionalidades do{' '}
                                        <b>Website</b>, bem como sobre
                                        lançamento de novas ferramentas ou
                                        funcionalidades; e (iii) classificar e
                                        agrupar informações de <b>Usuários</b>,
                                        assim como consolidar dados estatísticos
                                        a respeito desses, inclusive para
                                        definir a composição da base de{' '}
                                        <b>Usuários</b> Usuários do{' '}
                                        <b>Website</b> e o volume de tráfego em
                                        nossos servidores. Conduzimos pesquisa
                                        sobre aspectos de uso de nossos
                                        usuários, seus interesses e
                                        comportamentos com base na informação
                                        que nos é fornecida durante o uso do
                                        Website. Poderemos associar suas
                                        informações com as informações
                                        recolhidas de outros indivíduos para
                                        produzir estatísticas agregadas
                                        anônimas.
                                    </p>
                                    <p>
                                        O uso das informações coletadas e/ou
                                        armazenadas exige o processamento de
                                        dados que poderá ser feito pela própria{' '}
                                        <b>RSTCOM</b> ou por parceiros
                                        contratados para esse fim, que também
                                        estarão sujeitos a esta <b>Política</b>.
                                    </p>

                                    <h2>
                                        5. Do Prazo de Conservação dos Dados
                                        Pessoais
                                    </h2>
                                    <p>
                                        Os dados pessoais do <b>Usuário</b>{' '}
                                        serão conservados pelo prazo máximo de
                                        01 (um) ano, exceto se o <b>Usuário</b>{' '}
                                        solicitar a sua supressão antes do final
                                        deste prazo.
                                    </p>
                                    <p>
                                        Após o prazo de 01 (um) ano, todos os
                                        Dados Pessoais coletados para as
                                        transmissões ao vivo, ocorrido(s) em
                                        nosso <b>Website</b> serão totalmente
                                        repassados ao nosso parceiro comercial e
                                        então serão totalmente apagados de
                                        nossos servidores, bem como descartados,
                                        de forma segura e comprovada, sem a
                                        possibilidade de solicitar qualquer tipo
                                        de backup, recuperação de dados e/ou
                                        fornecimento dos mesmos.
                                    </p>
                                    <h2>
                                        6. Da Segurança no tratamento dos dados
                                        pessoais do usuário
                                    </h2>
                                    <p>
                                        A <b>RSTCOM</b> se compromete a aplicar
                                        as medidas técnicas e organizativas
                                        aptas a proteger os dados pessoais de
                                        acessos não autorizados e de situações
                                        de destruição, perda, alteração,
                                        comunicação ou difusão de tais dados.
                                    </p>
                                    <p>
                                        Para garantir a segurança, serão
                                        adotadas soluções que levem em
                                        consideração: as técnicas adequadas, os
                                        custos de aplicação, a natureza, o
                                        âmbito, o contexto e as finalidades do
                                        tratamento e os riscos para os direitos
                                        e liberdades do <b>Usuário</b>.
                                    </p>
                                    <p>
                                        No entanto, o <b>Website</b> se exime de
                                        responsabilidade por culpa exclusiva de
                                        terceiro, como em caso de ataque de
                                        hackers ou crackers ou culpa exclusiva
                                        do <b>Usuário</b>, como no caso em que
                                        ele mesmo transfere seus dados a
                                        terceiro. Nos comprometemos, ainda, a
                                        comunicar o <b>Usuário</b> em prazo
                                        adequado caso ocorra algum tipo de
                                        violação da segurança dos seus dados
                                        pessoais que possa lhe causar um alto
                                        risco para seus direitos e liberdades
                                        pessoais.
                                    </p>
                                    <p>
                                        A violação de dados pessoais é uma
                                        violação de segurança que provoque, de
                                        modo acidental ou ilícito, a destruição,
                                        a perda, a alteração, a divulgação ou o
                                        acesso não autorizado a dados pessoais
                                        transmitidos, conservados ou sujeitos a
                                        qualquer outro tipo de tratamento.
                                    </p>
                                    <p>
                                        Por fim, nos comprometemos a tratar os
                                        dados pessoais do <b>usuário</b> com
                                        confidencialidade, dentro dos limites
                                        legais.
                                    </p>
                                    <h2>7. Dos Dados de navegação (cookies)</h2>
                                    <p>
                                        Cookies são pequenos arquivos de texto
                                        enviados pelo site ao computador do{' '}
                                        <b>Usuário</b> e que nele ficam
                                        armazenados, com informações
                                        relacionadas à navegação do{' '}
                                        <b>Website</b>.
                                    </p>
                                    <p>
                                        Por meio dos cookies, pequenas
                                        quantidades de informação são
                                        armazenadas pelo navegador do{' '}
                                        <b>Usuário</b> para que nosso servidor
                                        possa lê-las posteriormente. Podem ser
                                        armazenados, por exemplo, dados sobre o
                                        dispositivo utilizado pelo{' '}
                                        <b>Usuário</b>, bem como seu local e
                                        horário de acesso ao site.
                                    </p>
                                    <p>
                                        Os cookies não permitem que qualquer
                                        arquivo ou informação sejam extraídos do
                                        disco rígido do <b>Usuário</b>, não
                                        sendo possível, ainda, que, por meio
                                        deles, se tenha acesso à informações
                                        pessoais que não tenham partido do{' '}
                                        <b>Usuário</b> ou da forma como utiliza
                                        os recursos do <b>Website</b>.
                                    </p>
                                    <p>
                                        É importante ressaltar que nem todo
                                        cookie contém informações que permitem a
                                        identificação do usuário, sendo que
                                        determinados tipos de cookies podem ser
                                        empregados simplesmente para que o site
                                        seja carregado corretamente ou para que
                                        suas funcionalidades funcionem do modo
                                        esperado.
                                    </p>
                                    <p>
                                        As informações eventualmente armazenadas
                                        em cookies que permitam identificar um{' '}
                                        <b>usuário</b> são consideradas dados
                                        pessoais. Dessa forma, todas as regras
                                        previstas nesta <b>Política</b> também
                                        lhes são aplicáveis.
                                    </p>
                                    <p>
                                        Os cookies do site são aqueles enviados
                                        ao computador ou dispositivo do{' '}
                                        <b>usuário</b> e administrador
                                        exclusivamente pelo <b>Website</b>.
                                    </p>
                                    <p>
                                        As informações coletadas por meio destes
                                        cookies são utilizadas para melhorar e
                                        personalizar a experiência do{' '}
                                        <b>usuário</b>, sendo que alguns cookies
                                        podem, por exemplo, ser utilizados para
                                        lembrar as preferências e escolhas do{' '}
                                        <b>usuário</b>, bem como para o
                                        oferecimento de conteúdo personalizado.
                                    </p>
                                    <p>
                                        Estes dados de navegação poderão, ainda,
                                        ser compartilhados com eventuais
                                        parceiros do <b>Website</b>, buscando o
                                        aprimoramento dos produtos e serviços
                                        ofertados ao <b>usuário</b>.
                                    </p>
                                    <p>
                                        Como a maioria dos serviços on-line,
                                        nosso site usa cookies primários e de
                                        terceiros para diversas finalidades. Os
                                        cookies primários são necessários,
                                        principalmente, para que o site funcione
                                        da maneira correta e cuida para que não
                                        seja coletado nenhum dado de
                                        identificação pessoal.
                                    </p>
                                    <p>
                                        Já os cookies de terceiros têm, como
                                        função principal, entender o desempenho
                                        do Website, a forma como você interage
                                        com ele, fornecer anúncios relevantes
                                        para você e, em geral, prover uma melhor
                                        experiência do Usuário ajudando a
                                        acelerar futuras interações com o nosso
                                        Website, além de manter nossos serviços
                                        seguros.
                                    </p>
                                    <p>
                                        Os cookies podem ser de “sessão” ou
                                        “persistentes”. Um cookie de sessão
                                        expira (ou seja, é excluído) quando você
                                        fecha o navegador. Um cookie persistente
                                        permanece até que expire ou você exclua
                                        os cookies através das configurações do
                                        seu navegador. As datas de expiração são
                                        definidas nos próprios cookies e podem
                                        variar em duração, dependendo da
                                        finalidade do cookie.
                                    </p>
                                    <p>
                                        Você pode limitar os cookies definidos
                                        no seu navegador, executando as etapas
                                        descritas abaixo. Observe que recusar
                                        cookies pode afetar sua capacidade de
                                        usar nossos serviços. Você pode alterar
                                        as configurações do seu navegador para
                                        excluir cookies que já foram definidos e
                                        rejeitar novos cookies. Para saber mais,
                                        visite as páginas de ajuda do seu
                                        navegador:
                                    </p>
                                    <ul>
                                        <li>Firefox</li>
                                        <li>Chrome</li>
                                        <li>Safari</li>
                                        <li>Microsoft Edge</li>
                                        <li>Internet Explorer</li>
                                    </ul>
                                    <h2>
                                        8. Das Alterações na presente Política
                                    </h2>
                                    <p>
                                        Poderemos ocasionalmente efetuar
                                        alterações na presente <b>Política</b>.
                                        Isto posto, na eventualidade de
                                        procederemos com alterações relevantes
                                        na presente <b>Política</b>,
                                        automaticamente, realizaremos a
                                        comunicação de uma nova versão dentro
                                        deste <b>Website</b>, por exemplo,
                                        exibindo um aviso na área de acesso.
                                        Pedimos que se atente sempre ao se logar
                                        para assistir as transmissões
                                        disponibilizadas a você. A sua contínua
                                        utilização do <b>Website</b> significa
                                        sua aderência e concordância com a atual
                                        versão desta <b>Política</b>.
                                    </p>

                                    <h2>9. Das Considerações finais</h2>
                                    <p>
                                        No entendimento da <b>RSTCOM</b> uma vez
                                        seguidas as diretrizes descritas neste
                                        documento, estas serão mais do que
                                        suficientes para preservar a idoneidade
                                        do relacionamento nos meios digitais.
                                    </p>
                                    <p>
                                        A utilização e/ou divulgação externa,
                                        autorizada pela <b>RSTCOM</b> da
                                        informação contida neste Website,
                                        implica a menção da origem e autoria
                                        dessa informação.
                                    </p>
                                    <p>
                                        O não apontamento por parte da{' '}
                                        <b>RSTCOM</b> com relação ao
                                        descumprimento pelo <b>Usuário</b> de
                                        qualquer cláusula, condição ou
                                        obrigações aqui previstas será
                                        considerada mera tolerância, não
                                        implicando em novação aos termos do
                                        presente instrumento, tampouco na
                                        renúncia da <b>RSTCOM</b> em exigir o
                                        cumprimento da obrigação do{' '}
                                        <b>Usuário</b>, que poderá fazê-lo a
                                        qualquer tempo, sob as penas aqui
                                        estabelecidas ou na forma da legislação
                                        positiva vigente.
                                    </p>
                                    <p>
                                        Se porventura alguma cláusula deste{' '}
                                        <b>Termos</b> for considerada ilegal,
                                        nula ou incapaz de ser cumprida por
                                        qualquer motivo, esta, será considerada
                                        uma cláusula independente da parte
                                        remanescente deste instrumento pactuado
                                        e não afetará a validade ou a
                                        aplicabilidade do cumprimento dos{' '}
                                        <b>termos</b> do restante do presente
                                        instrumento; este <b>Termos</b>,
                                        incluindo todos os avisos, instruções,
                                        regras ou políticas publicadas no
                                        Website ou encaminhadas ao e-mail do{' '}
                                        <b>Usuário</b>, constitui o acordo
                                        integral entre o Usuário e a{' '}
                                        <b>RSTCOM</b> sobre a utilização do
                                        Website, tornando sua eficácia imediata.
                                    </p>

                                    <h2>10. Das Considerações finais</h2>
                                    <p>
                                        Obrigado por ler a nossa <b>Política</b>
                                        . Caso tenha alguma dúvida, entre em
                                        contato conosco por meio do formulário
                                        "Entre em contato conosco" na Central de
                                        Privacidade, ou envie um email para
                                        contato@rstcom.com.br.
                                    </p>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>

                    <FooterBottom />
                </div>
            ) : (
                <LoadingScreen />
            )}
        </>
    );
};

export default PrivacyPolicy;
