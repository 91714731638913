/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../StoreContext/Context';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { useHistory, useParams } from 'react-router-dom';

import Aos from 'aos';
import api from '../../services/api';
import 'aos/dist/aos.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { i18n } from '../../translate/i18n';

//Lista emails presenciais
const emailList = [
  'abave@abave.org.br',
  'accamargo@sedu.es.gov.br',
  'adecir@sed.sc.gov.br',
  'agendaisraelbatista@gmail.com',
  'agotti@articule.org.br',
  'alan.meguerditchian@institutounibanco.org.br',
  'alexsandrosantos1980@gmail.com',
  'ana.inoue@itau-unibanco.com.br',
  'ana.miraglia@nexojornal.com.br',
  'ana.oliveira@unirio.br',
  'ana.pereira@institutosonhogrande.org',
  'andre@lemosjorge.com.br',
  'andrelflazaro@gmail.com',
  'Anna.penido@centrolemann.org.br',
  'annahelena@cenpec.org.br',
  'antonio@movimentopelabase.org.br',
  'audiliea@see.pb.gov.br',
  'barbosa.born@singularidades.com.br',
  'beatriz@labedu.org.br',
  'brenosr@al.insper.edu.br',
  'bruna.bido@rstcom.net',
  'bruno.rozenberg@educacao.mg.gov.br',
  'camilla.salmazi@jeduca.org.br',
  'cassio@gife.org.br',
  'cesar.callegari@uol.com.br',
  'cgee@mec.gov.br',
  'cibento@uol.com.br',
  'claudiacostin@hotmail.com',
  'claudiodemouracastro@inhotim.org.br',
  'claudiodemouracastro@me.com',
  'claudio.arromatte@itau-unibanco.com.br',
  'cleunicerehem@gmail.com',
  'contato@motriz.org',
  'contato@vozesdaeducacao.com.br',
  'cristina.gouveia@itau-unibanco.com.br',
  'cristovam@senador.gov.br',
  'david.cardozo@prof.edu.ma.gov.br',
  'davidsaad@natura.net',
  'dboraraposo@seduc.ro.gov.br',
  'ddsantos@fearp.usp.br',
  'danielamachado@palavraaberta.org.br',
  'diasporizando@gmail.com',
  'edneia.goncalves@acaoeducativa.org.br',
  'elijai@gmail.com',
  'evanilsonadv@gmail.com',
  'fabiana.dias@educ.al.gov.br',
  'fdwaltenberg@id.uff.br',
  'fernanda.akiyama-aoki@institutounibanco.org.br',
  'francisco.soares.ufmg@gmail.com',
  'ftran@imaginablefutures.com',
  'gab@seduc.ro.gov.br',
  'gab.smc@mma.gov.br',
  'gabinete@edu.ma.gov.br',
  'gabinete@edu.mt.gov.br',
  'gabinete@educacao.am.gov.br',
  'gabinete@seduc.to.gov.br',
  'gabinete-seb@mec.gov.br',
  'gabseeacre@gmail.com',
  'gabriela.lotta@gmail.com',
  'gastaovieira1555@gmail.com',
  'givaniaconceicao@gmail.com',
  'guheidrich@unicef.org',
  'gustavowei@gmail.com',
  'haroldocorrearocha@gmail.com',
  'helenaca@uol.com.br',
  'helenoaraujofilho@gmail.com',
  'ilonalustosa@gmail.com',
  'incolarum@hotmail.com',
  'iranegra@gmail.com',
  'jaana.nogueira@fgv.br',
  'janio.gomes@ims.com.br',
  'jgodinho@prof.educacao.rj.gov.br',
  'jmprevencao@yahoo.com.br',
  'joao.alegria@frm.org.br',
  'joao.borges@institutounibanco.org.br',
  'joaoms@bwsa.com.br',
  'jorge.lira@mat.ufc.br',
  'juliasantanna@cieb.net.br',
  'juliana.zimmerman@icebrasil.org.br',
  'katia@institutoreuna.org.br',
  'kezia.cruz@seduc.go.gov.br',
  'lara.simielli@fgv.br',
  'leila.melo@itau-unibanco.com.br',
  'leilasouza@mec.gov.br',
  'lia.glaz@telefonica.com',
  'limavanderleirodrigo@gmail.com',
  'lourivalaraujofilho@escola.pr.gov.br',
  'luis.meloni@gmail.com',
  'maira.carvalho@itau-unibanco.com.br',
  'marcelo.orticelli@insper.edu.br',
  'marcos.lisboa@insper.edu.br',
  'mariapilar@mec.gov.br',
  'melyssa.gomes@grupommeventos.com',
  'mifini@gmail.com',
  'mirela.carvalho@institutounibanco.org.br',
  'mj.noleto@unesco.org',
  'mluz@fmcsv.org.br',
  'mrodrigues@unicef.org',
  'msetubal@ftas.org.br',
  'natalia.risovas@fundacaoitau.org.br',
  'necasetubal@gmail.com',
  'nubia.silva-souza@institutounibanco.org.br',
  'ojeffersonsantos@gmail.com',
  'onebre23@gmail.com',
  'patricia.mota-guedes@itausocial.org.br',
  'patriciameloespanhol@gmail.com',
  'paula.miraglia@gmail.com',
  'paulo.miron@itau-unibanco.com.br',
  'pedroms@itau-unibanco.com.br',
  'pilarlacerda@hotmail.com',
  'presidencia@abpn.org.br',
  'priscila@todospelaeducacao.org.br',
  'rafaelparente@gmail.com',
  'rafael.rodrigues@institutounibanco.org.br',
  'rafaelly.wiest@grupodignidade.org.br',
  'rai_rr@live.com',
  'ricardo.madeira@institutounibanco.org.br',
  'ricardopb1@insper.edu.br',
  'roberto.catelli@acaoeducativa.org',
  'rodolfo.marino@itausa.com.br',
  'rodrigo@institutorodrigomendes.org.br',
  'rogerio.jesus38@enova.educacao.ba.gov.br',
  'romeufeix@gmail.com',
  'rosa.mariana@uol.com.br',
  'sandrasecretaria.seed@gmail.com',
  'santos.jaquelinelima@gmail.com',
  'saul@consorcioamazonialegal.gov.br',
  'secadigab@mec.gov.br',
  'seh.ortega@gmail.com',
  'sergio.ortega@rstcom.com.br',
  'sergioh.oortega@gmail.com',
  'sousasilvaeliana@gmail.com',
  'suelaine@geledes.org.br',
  'tania.120219@edutec.sed.ms.gov.br',
  'tatianaklix@porvir.org',
  'tereza.perez@comunidadeeducativa.org.br',
  'tiago.borba@institutounibanco.org.br',
  'tomaz@catalise.social',
  'valeria.marretto@itau-unibanco.com.br',
  'vanessa@consorcioamazonialegal.gov.br',
  'vanuza.hist@see.pb.gov.br',
  'wilson.fraga@seduc.ce.gov.br',
  'andrejorge@mec.gov.br',
  'cesarcallegari@mec.gov',
  'claudio.arromate@itau-unibanco.com.br',
  'cleonicerehem@mec.gov.br',
  'gastaovieira@mec.gov.br',
  'givaniasilva@mec.gov.br',
  'guibsontrindade@gmail.com',
  'helenofilho@mec.gov.br',
  'henrique.paim@fgv.br',
  'israelmatos@mec.gov.br',
  'm.abramovay@flacso.org.br',
  'marcelo.orticelli@insper.com.br',
  'marciasebastiani@mec.gov.br',
  'marianarosa@mec.gov.br',
  'mr.otero@unesco.org',
  'presidente@guetto.org',
  'fabianahiromi.sh@gmail.com',
];

const RegisterSchema = Yup.object().shape({
  name: Yup.string()
    .min(10, i18n.t('register.yupNameMin'))
    .required(i18n.t('register.yupNameRequired')),

  email: Yup.string()
    .required(i18n.t('register.yupEmailRequired'))
    .email('Formato de endereço de e-mail inválido')
    .test(
      'isLowerCase',
      'E-mail deve ser todo minúsculo',
      (email) => email && email === email.toLowerCase()
    ),

  fields: Yup.object().shape({
    acceptTerms: Yup.bool().oneOf([true], i18n.t('register.yupsendTerms')),
    acceptPrivacy: Yup.bool().oneOf([true], i18n.t('register.yupacceptData')),
    eventoPresencial: Yup.bool().default(false),
  }),
});

const SectionRegister = () => {
  const { inforRegister } = useContext(Context);

  const history = useHistory();
  const { eventKey } = useParams();
  const [isSubmitting, SetisSubmitting] = useState(false);
  const I18N_LOCALSTORAGE_KEY = 'i18nextLng';
  const [language] = useState(localStorage.getItem(I18N_LOCALSTORAGE_KEY));

  const [initialValues, SetInitialValues] = useState({
    name: '',
    email: '',
    role: 'participante',
    fields: {
      acceptPrivacy: false,
      acceptTerms: false,
      eventoPresencial: false,
    },
    eventKey: '',
    password: '',
  });

  const handleSubmit = async (values, actions) => {
    if (isSubmitting) return;
    SetisSubmitting(true);

    if (emailList.includes(values.email.toLowerCase())) {
      values.fields.eventoPresencial = true;
    }

    try {
      await api.post('participant', values);

      // Envio do email
      const obj2 = {
        subject:
          'Confirmação de inscrição: Seminário “Educação Cidadã e Convivência Democrática',
        to: 'no-reply@rstcom.stream',
        from: 'no-reply@rstcom.stream',
        fromname: 'Seminário Instituto Unibanco',
        urlmail: `https://4yourlive.com/${values.eventKey}/email/index.html`,
        name: values.name,
        email: values.email,
      };

      let formBody = [];

      for (const property in obj2) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(obj2[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
      }
      formBody = formBody.join('&');

      fetch('https://rstcom.live/mailgun-sendmail/index.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: formBody,
      }).then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
        },
        (error) => {
          console.log('FAILED...', error);
        }
      );

      if (values.fields.eventoPresencial) {
        toast.success(
          'Você é nossa/o convidada/o especial para o evento presencial. Essa inscrição é exclusiva para acompanhar a transmissão on-line.Para confirmar sua presença no Seminário, confira a mensagem enviada para o seu e-mail.',
          {
            position: 'top-right',
            autoClose: 12000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        toast.success(
          'Obrigado pela inscrição! No dia, o link da transmissão será enviado para o e-mail cadastrado. Acompanhe novidades sobre a programação do evento por esta página e pelas nossas redes.',
          {
            position: 'top-right',
            autoClose: 12000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }

      actions.resetForm();
      history.push(`/${eventKey}`);
      // window.open(`/${eventKey}/signin`, '_blank');
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          'Nao foi possivel realizar o cadastro, tente novamente.',
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } finally {
      SetisSubmitting(false);
    }

    actions.setSubmitting(false);
  };

  useEffect(() => {
    SetInitialValues((prev) => ({
      ...prev,
      password: inforRegister?.settings?.password,
      eventKey,
    }));
  }, [inforRegister, eventKey]);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      {inforRegister?.settings?.access?.show_signup_button && (
        <section id="section-register" className="light-mode">
          {/* <div className="wm wm-border light" data-aos="fade-down">
            {i18n.t('register.register')}
          </div> */}
          <div className="container">
            <div className="row">
              <div className="">
                <h1
                  className="col-md-8"
                  data-aos="fade-up"
                  style={{
                    color: '#065992',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  INSCREVA-SE E ACOMPANHE A TRANSMISSÃO DO EVENTO ONLINE
                </h1>
                {/* <div className="spacer-single" /> */}
              </div>
              <div className="col-md-8" data-aos="fade-up">
                <Formik
                  name="contactForm"
                  id="contact_form"
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={RegisterSchema}
                  onSubmit={handleSubmit}
                >
                  {({ handleChange, handleBlur, values, touched, errors }) => (
                    <Form>
                      <div className="row">
                        <div className="col-md-6 mb-0">
                          <div className="">
                            <input
                              type="hidden"
                              name="eventKey"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.eventKey}
                            />
                            <input
                              type="hidden"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />
                          </div>

                          <div className="form-group">
                            <Field
                              type="text"
                              name="name"
                              placeholder={i18n.t('register.placeholderName')}
                              className={`form-control ${
                                touched.name && errors.name ? 'is-invalid' : ''
                              }`}
                            />

                            <ErrorMessage
                              component="div"
                              name="name"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-group">
                            <Field
                              type="text"
                              name="email"
                              placeholder={i18n.t('register.placeholderEmail')}
                              className={`form-control ${
                                touched.email && errors.email
                                  ? 'is-invalid'
                                  : ''
                              }`}
                            />
                            <ErrorMessage
                              component="div"
                              name="email"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group form-check">
                        <Field
                          type="checkbox"
                          name="fields[acceptTerms]"
                          id="acceptTerms"
                          className={`form-check-input ${
                            errors?.fields?.acceptTerms &&
                            touched?.fields?.acceptTerms
                              ? ' is-invalid'
                              : ''
                          }`}
                        />
                        <label
                          htmlFor="acceptTerms"
                          className="form-check-label"
                        >
                          Aceitar {''}
                          <a
                            href="https://4yourlive.com/seminario-iu/terms-of-use"
                            target="_blank"
                            className="termos"
                          >
                            {i18n.t('register.termsConditions02')} RSTCom
                          </a>
                        </label>
                        <ErrorMessage
                          name="fields[acceptTerms]"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group form-check">
                        <Field
                          type="checkbox"
                          name="fields[acceptPrivacy]"
                          id="acceptPrivacy"
                          className={`form-check-input ${
                            errors?.fields?.acceptPrivacy &&
                            touched?.fields?.acceptPrivacy
                              ? ' is-invalid'
                              : ''
                          }`}
                        />

                        <label
                          htmlFor="acceptPrivacy"
                          className="form-check-label"
                        >
                          {/* Aceito receber notícias e atualizações do Instituto
                          Unibanco */}
                          Declaro ciência sobre o{' '}
                          <a
                            href="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fsemin%C3%A1rio-iu%2Fcustomization%2FAviso%20de%20Privacidade_%20Seminario%20Internacional%20nov24.pdf?alt=media&token=ed63bbe2-0c6a-44c8-924c-246e44f69c65"
                            target="_blank"
                            className="termos"
                          >
                            Aviso de Privacidade
                          </a>
                        </label>
                        <ErrorMessage
                          name="fields[acceptPrivacy]"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="row">
                        <div className="col-md-12 text-inherit">
                          <button
                            data-aos="fade-up"
                            type="submit"
                            className="btn"
                            // className="btn btn-line"
                            disabled={isSubmitting}
                            style={{
                              marginTop: '10px',
                              borderWidth: '1px',
                              backgroundColor: '#005b94',
                              color: '#fff',
                              fontWeight: 'bold',
                              // borderColor: '#93bad8',
                              // color: '#93bad8',
                            }}
                          >
                            {isSubmitting
                              ? `${i18n.t('register.registering')}`
                              : `${i18n.t('register.register')}`}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <ToastContainer />
        </section>
      )}
    </>
  );
};

export default SectionRegister;
