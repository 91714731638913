import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-scroll';

import Seo from '../../components/Seo';
import Footer from '../../components/Footer';

import LoadingScreen from '../../components/LoadingScreen';
import FooterBottom from '../../components/FooterBottom';

import './styled.css';

import { Context } from '../../StoreContext/Context';

const PrivacyPolicy = () => {
    const { loading, loadEvent, info } = useContext(Context);
    const { eventKey } = useParams();

    useEffect(() => {
        loadEvent(eventKey);
    }, []);

    return (
        <>
            <Seo />
            {loading === false ? (
                <div>
                    <div id="wrapper">
                        <div className="header-politica">
                            <Link to={`/${eventKey}`}>
                                <img
                                    className="logo w-75"
                                    src={info?.logo}
                                    alt=""
                                />
                            </Link>

                            <Link to="/novalp" smooth duration={1000}>
                                Início
                            </Link>
                        </div>

                        <div id="content" className="no-bottom no-top">
                            <div className="container ">
                                <div className="text-terms">
                                    <h1>
                                        TERMOS E CONDIÇÕES DE USO DO WEBSITE DE
                                        TRANSMISSÃO AO VIVO - LIVE DA RSTCOM
                                    </h1>

                                    <p className="text-p">
                                        Este <b>Website</b> [incluir link], foi
                                        desenvolvido pela{' '}
                                        <b>
                                            RSTCOM Comunicação Estratégica Ltda.
                                        </b>
                                        , sociedade empresária limitada, com
                                        sede na Avenida Queiroz Filho, 1700 –
                                        Torre E, SL 213, Vila Hamburguesa,
                                        cidade de São Paulo, Estado de São
                                        Paulo, CEP: 05319-000, inscrita no
                                        CNPJ/MF sob o n.º 21.297.131/0001-52 (
                                        <b>“RSTCOM”</b>), sob o modelo de
                                        plataforma de acesso “online”, com a
                                        função de transmissão ao vivo – live (
                                        <b>"Website"</b>).
                                    </p>
                                    <p>
                                        A <b>RSTCOM</b> é detentora de todos os
                                        direitos do <b>Website</b>.
                                    </p>
                                    <p>
                                        <b>Usuário</b> fica definido, no escopo
                                        desse{' '}
                                        <b>
                                            Termos e Condições de Uso do Website
                                            de Transmissão ao Vivo (“Termos de
                                            Uso”)
                                        </b>{' '}
                                        como agente que fará uso do{' '}
                                        <b>Website</b> a qualquer momento no
                                        escopo de uma conta.
                                    </p>
                                    <p>
                                        O <b>Website</b>, oferecido pela{' '}
                                        <b>RSTCOM</b>, ao <b>Usuário</b>{' '}
                                        (pessoas físicas ou jurídicas) 1está
                                        condicionado à aceitação e ao
                                        cumprimento dos descritos abaixo. Para
                                        fazer uso do <b>Website</b>, é preciso:{' '}
                                        <b>(i)</b> ler atentamente os termos
                                        descritos abaixo; <b>(ii)</b> concordar
                                        expressamente com os mesmos; e{' '}
                                        <b>(iii)</b> respeitar os termos aqui
                                        estabelecidos. Caso não concorde com
                                        quaisquer dispositivos dos{' '}
                                        <b>Termos de Uso</b>, por favor não
                                        acesse ou utilize o nosso <b>Website</b>
                                        .
                                    </p>
                                    <h2>1. Do Objeto</h2>
                                    <p>
                                        O objeto do presente{' '}
                                        <b>Termos de Uso</b> é a regulamentação
                                        da licença de uso e devida prestação de
                                        serviço pelo <b>Usuário</b>, com prazo
                                        determinado, normatização da utilização
                                        do <b>Website</b> pelo <b>Usuário</b> em
                                        conformidade com as normas de conduta e
                                        preceitos legais vigentes, bem como
                                        definição da política de privacidade dos
                                        dados colhidos pelo Website.
                                    </p>

                                    <h2>2. Da utilização do serviço </h2>
                                    <p>
                                        O <b>Usuário</b> em posse de seu nome de{' '}
                                        <b>Usuário</b> e senha de acesso está
                                        devidamente habilitado e com acesso ao
                                        <b>Website</b> e poderá utilizar,
                                        conforme disponibilidade, do timeline,
                                        chat, face live, “pergunte ao
                                        palestrante”, links e pesquisas.
                                    </p>
                                    <p>
                                        Usuários menores de 18 anos de idade
                                        apenas poderão efetuar o Cadastro,
                                        conforme abaixo definido, e utilizar o
                                        Website mediante representação ou
                                        assistência de seus responsáveis legais,
                                        que serão responsáveis por todo e
                                        qualquer ato ilícito cometido pelo menor
                                        com relação ao Cadastro e WebSite.
                                    </p>

                                    <h2>3. Da Conduta no Website</h2>
                                    <p>
                                        O uso do <b>Website</b> colocado à
                                        disposição dos <b>Usuários</b>, somente
                                        poderá ser realizado mediante o prévio e
                                        completo cadastro do <b>Usuário</b>,
                                        sujeito a aceitação, pela <b>RST COM</b>
                                        , dos dados fornecidos pelo Usuário{' '}
                                        <b>("Cadastro")</b>. Não será permitido
                                        mais de 1 (um) Cadastro por{' '}
                                        <b>Usuário</b>.
                                    </p>
                                    <p>
                                        Ao preencher o formulário de{' '}
                                        <b>Cadastro</b>, o <b>Usuário</b>{' '}
                                        concorda em cumprir com as seguintes
                                        diretrizes:
                                    </p>
                                    <ul>
                                        <li>
                                            Não é permitido transmitir
                                            informação, dado, texto, gráficos,
                                            sons, fotografias, vídeos, mensagens
                                            ou outro conteúdo que tenham caráter
                                            ilegal, ofensivo, impreciso,
                                            difamatório, obsceno, vexatório,
                                            fraudulento, prejudicial, ameaçador
                                            ou abusivo que contenha expressões
                                            de ódio contra pessoas ou grupos, ou
                                            que contenha pornografia infantil,
                                            pornografia explícita ou violenta,
                                            conteúdo que possa ser danoso a
                                            menores, que contenha insultos ou
                                            ameaças religiosas ou raciais, ou
                                            que incentive danos morais
                                            (incluindo os corporais) e
                                            patrimoniais, ou que possa violar
                                            qualquer direito de terceiro,
                                            notadamente os direitos humanos;
                                        </li>
                                        <li>
                                            O <b>Usuário</b> será o único e
                                            exclusivo responsável pelo uso que
                                            fizer dos <b>Website</b>, sendo
                                            certo que a utilização destes para
                                            fins ilícitos não importará em
                                            qualquer responsabilização para a{' '}
                                            <b>RSTCOM</b>;
                                        </li>
                                        <li>
                                            Não interferir no uso de outros{' '}
                                            <b>Usuários</b> do <b>Website</b>;
                                        </li>
                                        <li>
                                            Disseminar ou tentar enviar vírus de
                                            computador, incluindo,
                                            <u> mas não se limitando a</u>,
                                            cavalos de tróia,
                                            <i> worms, keyboard loggers</i>,
                                            bombas relógio,{' '}
                                            <i>spywares, adwares, cancelbots</i>{' '}
                                            ou qualquer outro código ou programa
                                            invasivo ou malicioso;
                                        </li>
                                        <li>
                                            Disseminar ou tentar enviar{' '}
                                            <i>spams</i> ou qualquer outro
                                            material que possa modificar,
                                            interromper ou interferir no
                                            funcionamento, operação ou
                                            manutenção do <b>Website</b>, das
                                            redes de comunicação, ou, de
                                            qualquer forma, prejudicar o uso e
                                            qualidade oferecida;
                                        </li>
                                        <li>
                                            Enviar ou tentar disseminar
                                            materiais que passivamente ou
                                            ativamente coletam informações, como{' '}
                                            <i>
                                                gifs, web bugs, cookies e
                                                spywares
                                            </i>
                                            , entre outros;
                                        </li>
                                        <li>
                                            Solicitar informações de login e/ou
                                            de acesso de conta pertencente a
                                            outro <b>Usuário</b>;
                                        </li>
                                        <li>
                                            Interferir ou quebrar qualquer
                                            sistema de segurança do{' '}
                                            <b>Website</b>, especialmente
                                            aqueles com a finalidade de limitar
                                            e controlar o acesso e uso do{' '}
                                            <b>Website</b> e seus respectivos
                                            conteúdos;
                                        </li>
                                        <li>
                                            Utilizar ferramentas ou proxy
                                            anônimo com a finalidade de tornar
                                            seu endereço de IP anônimo;
                                        </li>
                                        <li>
                                            Usar, desenvolver ou lançar sistemas
                                            automáticos que possam acessar o{' '}
                                            <b>Website</b>, como{' '}
                                            <i>spiders, robots, scrapers</i> ou
                                            leitores <i>offline</i>;
                                        </li>
                                        <li>
                                            Utilizar dispositivo,{' '}
                                            <i>software</i> ou outro recurso que
                                            interfira nas atividades e operações
                                            do <b>Website</b>, bem como nos seus
                                            conteúdos, descrições, contas ou
                                            seus bancos de dados;
                                        </li>
                                        <li>
                                            Não é permitido usar o{' '}
                                            <b>Website</b> para divulgar, vender
                                            produtos ou serviços de ou para
                                            terceiros, sorteios ou esquemas de
                                            pirâmide;
                                        </li>
                                        <li>
                                            Não se passar por qualquer pessoa ou
                                            entidade, declarar falsamente ou
                                            deturpar sua afiliação com uma
                                            pessoa ou entidade;
                                        </li>
                                        <li>
                                            Não enviar ou transmitir conteúdo
                                            que o <b>Usuário</b> não tem o
                                            direito de publicar ou transmitir
                                            sob qualquer lei ou sob relações
                                            contratuais ou fiduciárias (tais
                                            como informações privilegiadas,
                                            informações confidenciais e etc);
                                        </li>
                                        <li>
                                            Não usar o <b>Website</b> para
                                            solicitar, obter ou armazenar dados
                                            pessoais ou senhas de outros{' '}
                                            <b>Usuários</b>.
                                        </li>
                                        <li>
                                            Manter as Informações do{' '}
                                            <b>Usuário</b> sempre atualizadas,
                                            de modo que elas permaneçam
                                            verdadeiras, certas, exatas, atuais
                                            e completas.
                                        </li>
                                        <li>
                                            Caso o <b>Usuário</b> forneça
                                            informação falsa, incorreta,
                                            desatualizada ou incompleta, ou,
                                            ainda, a <b>RSTCOM</b> tenha motivos
                                            razoáveis para suspeitar que as
                                            Informações do <b>Usuário</b> sejam
                                            falsas, incorretas, desatualizadas
                                            ou incompletas, bem como se o{' '}
                                            <b>Usuário</b> estiver em desacordo
                                            com os Termos e Condições e demais
                                            políticas da <b>RSTCOM</b>, esta
                                            terá o direito de suspender ou
                                            encerrar o acesso do <b>Usuário</b>{' '}
                                            ao Website, bem como de rejeitar
                                            qualquer novo acesso pelo{' '}
                                            <b>Usuário</b>;{' '}
                                        </li>
                                        <li>
                                            Ao término do preenchimento do
                                            formulário de <b>Cadastro</b>, o{' '}
                                            <b>Usuário</b> será informado por
                                            meio do mesmo endereço de e-mail
                                            informado no formulário sobre a
                                            aceitação e registro de seu{' '}
                                            <b>Cadastro</b>;{' '}
                                        </li>
                                        <li>
                                            O <b>Usuário</b> deverá ter
                                            capacidade legal e deter todas as
                                            autorizações e permissões
                                            necessárias para realizar o
                                            <b>Cadastro</b> e para utilizar o{' '}
                                            <b>Website</b>, não se encontrando,
                                            em qualquer hipótese, sob
                                            impedimento legal e/ou contratual;
                                        </li>
                                        <li>
                                            O nome do <b>Usuário</b> e a senha
                                            de <b>Cadastro</b> são de uso
                                            estritamente pessoal e não deverão
                                            ser utilizados por terceiros não
                                            autorizados (incluindo funcionários
                                            ou representantes do <b>Usuário</b>{' '}
                                            que não tenham sido expressamente
                                            autorizados). A guarda, o sigilo e a
                                            utilização do nome e senha do{' '}
                                            <b>Usuário</b> são de exclusiva
                                            responsabilidade do <b>Usuário</b>,
                                            que se compromete a fazer um uso
                                            diligente, bem como a não colocá-los
                                            à disposição de terceiros não
                                            autorizados.
                                        </li>
                                        <li>
                                            O <b>Usuário</b> compromete-se a
                                            comunicar imediatamente à{' '}
                                            <b>RSTCOM</b> em caso de perda,
                                            roubo ou utilização suspeita do nome
                                            do <b>Usuário</b> ou da senha, bem
                                            como caso constate haver qualquer
                                            risco de acesso às mesmas por um
                                            terceiro não autorizado. Nesse caso,
                                            o <b>Usuário</b> deverá comunicar
                                            imediatamente à Central de
                                            Atendimento ao Cliente da{' '}
                                            <b>RSTCOM</b>, através do
                                            e-mail contato@rstcom.com.br;
                                        </li>
                                        <li>
                                            A <b>RSTCOM</b> reserva-se o direito
                                            de negar ou retirar o acesso ao{' '}
                                            <b>Website</b>, a qualquer momento e
                                            sem necessidade de aviso prévio,
                                            àqueles Usuários que não estejam em
                                            estrito cumprimento com os presentes{' '}
                                            <b>Termos de Uso</b>;
                                        </li>
                                        <li>
                                            Não é permitido ao <b>Usuário</b>{' '}
                                            ceder, transferir, vender, alugar,
                                            ou de qualquer outro modo dispor do{' '}
                                            <b>Cadastro</b> a terceiros,
                                            tampouco criar novo <b>Cadastro</b>{' '}
                                            após o cancelamento do{' '}
                                            <b>Cadastro</b> original devido a
                                            infrações aos <b>Termos de Uso</b>,
                                            exceto se de outra forma autorizado
                                            pela <b>RSTCOM</b>;
                                        </li>
                                        <li>
                                            A <b>RSTCOM</b> reserva-se o direito
                                            de recusar qualquer solicitação de{' '}
                                            <b>Cadastro</b> e de cancelar um{' '}
                                            <b>Cadastro</b> previamente aceito.
                                        </li>
                                    </ul>
                                    <p>
                                        A <b>RSTCOM</b>, com objetivo de
                                        preservação da qualidade e
                                        funcionalidade do <b>Website</b>,
                                        reserva-se o direito de modificar
                                        unilateralmente, em qualquer momento e
                                        sem prévio aviso, a apresentação e
                                        configuração do <b>Website</b>, assim
                                        como também as condições ora requeridas
                                        para utilização do mesmo, estando
                                        facultado ao <b>Usuário</b> a
                                        solicitação do cancelamento do seu{' '}
                                        <b>Cadastro</b>; tendo em vista a alta
                                        volatilidade da Internet e o fato de que
                                        a <b>RSTCOM</b> não detém o controle
                                        desta, a disponibilidade e a
                                        continuidade do funcionamento do{' '}
                                        <b>Website</b> não são garantidas pela{' '}
                                        <b>RSTCOM</b>, que fica isenta de toda e
                                        qualquer responsabilidade relacionada a
                                        eventuais danos e prejuízos de qualquer
                                        natureza que porventura venham a ser
                                        causados em razão da indisponibilidade
                                        ou da falta de continuidade do{' '}
                                        <b>Website</b>, bem como eventuais
                                        funcionalidades do <b>Website</b>, as
                                        quais serão aplicáveis, a depender da
                                        contratação pela{' '}
                                        <b>
                                            Agência Origami no Evento Prêmio
                                            Mulheres que Transformam.
                                        </b>
                                    </p>
                                    <p>
                                        O <b>Usuário</b> reconhece e concorda
                                        que todas as informações e conteúdos
                                        disponibilizados no <b>Website</b> pela{' '}
                                        <b>
                                            Agência Origami no Evento Prêmio
                                            Mulheres que Transformam
                                        </b>
                                        , que foram desenvolvidos ou não pela{' '}
                                        <b>
                                            RSTCOM para transmissão ao-vivo
                                            (live) Evento Prêmio Mulheres que
                                            Transformam
                                        </b>{' '}
                                        e, por isso, não poderão ser
                                        disponibilizadas a terceiros. Salvo se
                                        diversamente previsto neste{' '}
                                        <b>Termos de Uso</b>, o <b>Usuário</b>{' '}
                                        concorda em não reproduzir, duplicar,
                                        copiar, vender, revender ou explorar
                                        qualquer parte das informações e/ou
                                        conteúdos do <b>Website</b>, bem como se
                                        obriga a utilizá-lo de forma lícita,
                                        sendo vedada a reprodução, distribuição,
                                        transformação, comercialização ou
                                        modificação do conteúdo, sem a prévia e
                                        expressa autorização da <b>RSTCOM</b>{' '}
                                        e/ou da{' '}
                                        <b>
                                            Agência Origami no Evento Prêmio
                                            Mulheres que Transformam
                                        </b>
                                        , bem como fica vedado o fornecimento de
                                        login e senha para terceiros não
                                        cadastrados.
                                    </p>
                                    <h2>4. Da Violação do Termo</h2>
                                    <p>
                                        Na eventualidade de o <b>Usuário</b>{' '}
                                        violar qualquer item do presente{' '}
                                        <b>Termos de Uso</b> ou informações de
                                        outros <b>Usuários</b> postadas no{' '}
                                        <b>Website</b>, ou no caso de a{' '}
                                        <b>RSTCOM</b> compreender que tal
                                        conduta ou conteúdo é ofensivo ou
                                        ilegal, bem como que viola os direitos
                                        ou ameaça prejudicar terceiros e que
                                        podem criar responsabilidade para os
                                        mesmos, a <b>RSTCOM</b> se reserva no
                                        direito de investigar e tomar as medidas
                                        legais cabíveis, incluindo a remoção de
                                        conteúdo do <b>Website</b>, notificação
                                        das autoridades competentes sobre a
                                        fonte do conteúdo, impedindo-o de
                                        acessar o <b>Website</b> e promover o
                                        encerramento de seu acesso sem aviso
                                        prévio.
                                    </p>

                                    <h2>
                                        5. Da Política de utilização de login e
                                        senha do Website
                                    </h2>
                                    <p>
                                        Você, <b>Usuário</b>, elegível para o
                                        uso deste <b>Website</b>, recebeu, o
                                        login e senha de uso pessoal e
                                        intransferível, para acesso a
                                        transmissão ao vivo da{' '}
                                        <b>
                                            Agência Origami no Evento Prêmio
                                            Mulheres que Transformam
                                        </b>
                                        . Dessa forma, você, <b>Usuário</b>,
                                        compromete-se a fazer uso da senha, de
                                        forma segura e confidencial, zelando por
                                        sua guarda e confidencialidade,
                                        declarando-se ciente de que não poderá
                                        vender, transferir, ceder ou emprestar a
                                        outrem, a qualquer título, a senha, que
                                        é de caráter pessoal e intransferível. O
                                        extravio, roubo ou perda da senha de
                                        acesso pelo <b>Usuário</b>, deverá ser
                                        comunicado imediatamente por escrito à
                                        contato@rstcom.com.br, a fim de que
                                        possa bloqueá-la e disponibilizar nova
                                        senha. Fica o <b>Usuário</b> ciente de
                                        que enquanto a <b>RSTCOM</b> não for
                                        cientificada dessa ocorrência, o{' '}
                                        <b>Usuário</b> ficará responsável pelos
                                        atos praticados por terceiros, através
                                        da utilização da senha, que provoquem
                                        quebra de confidencialidade e/ou outros
                                        danos que venham a ocorrer. É de
                                        integral responsabilidade do{' '}
                                        <b>Usuário</b>, qualquer prejuízo ou
                                        dano que vierem a sofrer ou causarem ao
                                        ator da transmissão e/ou a terceiros, em
                                        decorrência do uso inadequado ou
                                        indevido de sua senha, seja por conduta
                                        culposa ou dolosa. A <b>RSTCOM</b>{' '}
                                        exonera-se de toda e qualquer
                                        responsabilidade decorrente do uso
                                        indevido, negligente ou imprudente das
                                        senhas de acesso concedidas ao{' '}
                                        <b>Usuário</b> elegível a esta
                                        transmissão.
                                    </p>

                                    <h2>6. Da Propriedade Intelectual</h2>
                                    <p>
                                        O conteúdo deste <b>Website</b> está
                                        protegido por leis de propriedade
                                        intelectual aplicáveis e todo o conteúdo
                                        é de propriedade da <b>RSTCOM</b>. Todo
                                        o texto, a formatação (incluindo, sem
                                        limitação, a seleção, coordenação e
                                        disposição dos materiais no{' '}
                                        <b>Website</b>, as imagens, gráficos,
                                        animação, ferramentas, widgets,
                                        aplicativos comerciais, vídeos, música,
                                        sons, artigos, cópia, materiais
                                        criativos, fotos, marcas comerciais,
                                        marcas de serviço, nomes comerciais e
                                        logotipos) e outros materiais e
                                        informações contidos neste{' '}
                                        <b>Website</b> estão sujeitos aos
                                        direitos de propriedade intelectual da{' '}
                                        <b>RSTCOM</b>, e seus respectivos
                                        licenciadores e licenciados. Estes
                                        materiais não podem ser copiados, nem
                                        ter a engenharia revertida,
                                        descompilados, desmontados, modificados,
                                        reenviados para outros sites,
                                        enquadrados, profundamente ligados,
                                        alterados, ou de outra forma
                                        distribuídos, redistribuídos,
                                        licenciados, sublicenciados ou
                                        transferidos de qualquer forma por você
                                        ou outrem. Nada contido neste{' '}
                                        <b>Website</b> deve ser interpretado
                                        como concessão, por implicação,
                                        preclusão ou, de outra forma, qualquer
                                        licença ou direito para fazer uso
                                        comercial de qualquer marca, direito de
                                        propriedade intelectual ou material com
                                        direitos autorais sem a permissão prévia
                                        por escrito da <b>RSTCOM</b>. As marcas,
                                        logotipos, nomes comerciais e marcas de
                                        serviços, registrados ou não
                                        (coletivamente as{' '}
                                        <b>
                                            <u>"Marcas"</u>
                                        </b>
                                        ) exibidos neste <b>Website</b> são
                                        marcas comerciais da <b>RSTCOM</b> e de
                                        seus parceiros terceirizados de aliança.
                                        Nada contido no <b>Website</b> da{' '}
                                        <b>RSTCOM</b> deve ser interpretado como
                                        concessão, por implicação ou de outra
                                        forma, de qualquer licença ou direito de
                                        usar qualquer marca exibida no{' '}
                                        <b>Website</b> sem a permissão por
                                        escrito da <b>RSTCOM</b>.
                                    </p>

                                    <h2>7. Das Disposições Gerais</h2>
                                    <p>
                                        O <b>Usuário</b> reconhece e concorda
                                        que todas as informações e conteúdos
                                        disponibilizados no <b>Website</b> pela
                                        RSTCOM são de propriedade ou foram
                                        devidamente desenvolvidos pela RSTCOM e,
                                        por isso, não poderão ser
                                        disponibilizadas a terceiros. Salvo se
                                        diversamente previsto neste Termos de
                                        Uso, o <b>Usuário</b> concorda em não
                                        reproduzir, duplicar, copiar, vender,
                                        revender ou explorar qualquer parte das
                                        informações e conteúdos do{' '}
                                        <b>Website</b> , bem como se obriga a
                                        utilizar todo o conteúdo do{' '}
                                        <b>Website</b> de forma lícita, sendo
                                        vedada a reprodução, distribuição,
                                        transformação, comercialização ou
                                        modificação do conteúdo, sem a prévia e
                                        expressa autorização da <b>RSTCOM</b>,
                                        bem como fica vedado o fornecimento de
                                        login e senha para terceiros não
                                        cadastrados.
                                    </p>
                                    <p>
                                        Salvo disposição em contrário, todo o
                                        conteúdo disponível no <b>Website</b>,
                                        tais como informações, textos, layouts,
                                        designs, formatações, manuais, imagens,
                                        vídeos, áudios, gráficos, estatísticas,
                                        artigos, marcas, entre outros, pertencem
                                        à <b>RSTCOM</b> e a terceiros que
                                        licitamente cederam seu direito de uso,
                                        sendo certo que o uso ou acesso ao{' '}
                                        <b>Website</b> e/ou a qualquer dos
                                        serviços ali contidos não será entendido
                                        como atribuição de direitos ao Usuário
                                        de nenhuma forma.
                                    </p>
                                    <p>
                                        Sem prejuízo de outras medidas que
                                        entender cabíveis, a <b>RSTCOM</b>{' '}
                                        poderá advertir, suspender ou cancelar,
                                        temporária ou definitivamente, o
                                        cadastro do <b>Usuário</b>, a qualquer
                                        tempo, iniciando as ações legais
                                        cabíveis e/ou suspendendo a execução
                                        destes <b>Termos de Uso</b> se o{' '}
                                        <b>Usuário </b>não cumprir qualquer
                                        dispositivo aqui previsto e demais
                                        políticas de uso. A <b>RSTCOM</b> ainda
                                        poderá tomar tais medidas se não puder
                                        ser verificada a identidade do{' '}
                                        <b>Usuário</b> ou qualquer informação
                                        fornecida por ele esteja incorreta.
                                    </p>
                                    <p>
                                        Para enviar notificações referentes ao{' '}
                                        <b>Website</b>, bem como para alterar ou
                                        cancelar o <b>Cadastro</b>, o{' '}
                                        <b>Usuário</b> deverá enviar um e-mail
                                        ao endereço contato@rstcom.com.br.
                                    </p>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>

                    <FooterBottom />
                </div>
            ) : (
                <LoadingScreen />
            )}
        </>
    );
};

export default PrivacyPolicy;
