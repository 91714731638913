import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Context } from "./Context";
import moment from 'moment';
import api from '../services/api';

const Provider = ({ children }) => {

  const [loading, setLoading] = useState(true);

  const [info, setInfo] = useState({});
  const [countdown, setCountdown] = useState();
  const [inforRegister, SetInforRegister] = useState();
  const [infoFaq, setInfoFaq] = useState();
  const [agenda, setAgenda] = useState()
  const [seoInfo, SetSeoInfo] = useState({});
  const [favicon, SetFavicon] = useState();
  const [speaker, setSpeaker] = useState()
  const [clientName, setClientName] = useState()
  const [lpAbout, setLpAbout] = useState()
  const [config, setConfig] = useState()
  const [access, setAccess] = useState()

  function loadEvent(eventKey) {
    api
      .get(`/event/public-info?key=${eventKey}`)
      .then((res) => {
        // Pegando Seo
        SetSeoInfo(res?.data);
        // console.log(res?.data);

        // Pegando Favicon
        SetFavicon(res?.data?.customization?.favicon);
        // console.log(res?.data?.customization?.favicon);

        // Pegando o Background Header/banner/Logo
        setInfo(res?.data?.customization);
        // console.log(res?.data?.customization?.bannerlp);
        setAgenda(res?.data?.agenda);
        // Pegando o contador

        //const cont = new Date(`2021-07-24 19:00`).getTime(); //API = 24-07-2021 19:00
        //const cont = new Date(res?.data?.eventdatelp).getTime();

        // Pegando o contador
        const data = String(res?.data?.eventdatelp).split(' ')
        const atad = data[0].split('-');
        const ss = atad[2] + '-' + atad[1] + '-' + atad[0]
        const daaaR = ss+ `T${data[1]}`
        console.log('dada',daaaR)
        const cont = new Date(daaaR).getTime();
        console.log('cont', cont)
        setCountdown(cont);
        //console.log(date);

        setLpAbout(res?.data?.lp)

        // Pegando a senha
        SetInforRegister(res?.data);
        // console.log(res?.data?.settings);

        setClientName(res?.data?.clientName);

        setLoading(false);

        // Pegando faq
        setInfoFaq(res?.data?.settings);
        // console.log(res?.data?.settings);

        setConfig(res?.data?.settings?.config)

        setSpeaker(res?.data?.speakers)

        setAccess(res?.data?.settings?.access)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  return (
    <Context.Provider
      value={{
        loading, setLoading,
        info, setInfo,
        countdown, setCountdown,
        inforRegister, SetInforRegister,
        infoFaq, setInfoFaq,
        seoInfo, SetSeoInfo,
        favicon, SetFavicon,
        loadEvent,
        agenda, setAgenda,
        speaker, setSpeaker,
        clientName, setClientName,
        lpAbout, setLpAbout,
        config, setConfig,
        access, setAccess
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default Provider;
