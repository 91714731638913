import React from 'react';
import RedesSocial from '../RedesSocial';
import { i18n } from '../../translate/i18n';

const SpeakersInfo = ({ speaker }) => (
  <>
    <div className="col-lg-4 col-md-6">
      {/* <div className="col-lg-2 col-md-6"> */}
      {/* team member */}
      <div className="de-team-list">
        <div className="team-pic" style={{ textAlign: 'center' }}>
          <img src={speaker?.avatar} className="img-responsive" alt="" />
          <h3
            style={{ marginTop: '20px', color: '#e2ed09', marginBottom: '2px' }}
          >
            {speaker?.name}
          </h3>
          <span style={{ color: '#e2ed09' }}>{speaker?.profession?.pt}</span>
        </div>
        <div className="team-desc scroll-behavior">
          <h3>{speaker?.name}</h3>
          <p className="lead">{speaker?.profission}</p>
          <p style={{ marginBottom: '3px' }}>{speaker?.crm}</p>
          <div className="line"></div>
          <p
            dangerouslySetInnerHTML={{
              __html:
                i18n.language.substring(0, 2) === 'pt'
                  ? speaker?.description?.pt
                  : speaker?.description?.es,
            }}
          />
        </div>
        {/* <div className="small-border" /> */}
        {/* <div className="social">
            <RedesSocial speaker={speaker} />
          </div> */}
      </div>
      {/* team close */}
    </div>
  </>
);

export default SpeakersInfo;
