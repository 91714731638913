import { i18n } from '../../translate/i18n';

const FooterBottom = () => {
  return (
    <>
      <section
        id="section-footer"
        className="text-light pt-3 pb-3 text-center"
        data-bgcolor="#222222"
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Fseminario-iu%2Fcustomization%2FLogo_IU-branco.png?alt=media&token=a73ac5aa-66f7-48d1-a1b0-7c700c8c608d"
          width={75}
        />
        {/* <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 text-center">
              <a href="http://rstcom.com.br/" target="_blank">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/images%2Flogo-rstcom.png?alt=media&token=b80e35ff-d708-440b-85a6-ae7465d95179"
                  width={35}
                />
                <br />
              </a>
              <small>{i18n.t('footer.footerBottom')}</small>
            </div>
            <div className="col-md-6 text-center align-items-center mt-4 mt-md-0">
              <p className="mb-0 text-light">
                {i18n.t('footer.problems')}{' '}
                <a
                  href="https://rstcom.live/faq/rstcom/"
                  target="_blank"
                  className="text-secondary"
                >
                  {i18n.t('footer.clickHere')}
                </a>
              </p>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default FooterBottom;
