import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-scroll';
import { useParams } from 'react-router-dom';

import { i18n } from '../../translate/i18n';

import Hamburger from './Hamburger';

import './header.css';

import { Context } from '../../StoreContext/Context';

const Header = () => {
  const { info, inforRegister, config, access } = useContext(Context);
  const [isMobile, SetIsMobile] = useState(
    window.matchMedia('(max-width: 992px)').matches
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      SetIsMobile(window.matchMedia('(max-width: 992px)').matches);
    });
  });

  const { eventKey } = useParams();
  const [menuOpen, setMenuOpen] = useState(false);

  const I18N_LOCALSTORAGE_KEY = 'i18nextLng';
  const [language] = useState(localStorage.getItem(I18N_LOCALSTORAGE_KEY));
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to manage dropdown visibility

  const handleSelectchange = (languageCode) => {
    localStorage.setItem(I18N_LOCALSTORAGE_KEY, languageCode);
    window.location.reload(); // Reload the page to apply the language change
    // localStorage.setItem(I18N_LOCALSTORAGE_KEY, event.target.value);
    // window.location = window.location;
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen); // Toggle the dropdown visibility
  };

  const closeDropdown = () => {
    setDropdownOpen(false); // Close the dropdown
  };

  return (
    <header
      className="transparent header"
      style={{
        background: `linear-gradient(90deg, ${info?.backgroundHeaderGradientColor1} 0%, ${info?.backgroundHeaderGradientColor2} 90%)`,
        // background: `linear-gradient(90deg, ${info?.headerBgColor1} 0%, ${info?.headerBgColor2} 90%)`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 menulist">
            {/* logo begin */}
            <div id="logo" className="header__brand">
              <Link
                style={{ color: `${info?.fontColor}` }}
                to="dalk-image"
                smooth
                duration={1000}
              >
                <img className="logo" src={info?.logo} alt="" />
              </Link>
            </div>
            {/* logo close */}
            {/* small button begin */}
            <div
              className="header__nav-toggle"
              onClick={() => {
                setMenuOpen((o) => !o);
              }}
            >
              {' '}
              <Hamburger />
            </div>
            {/* small button close */}
            {/* mainmenu begin */}
            <nav
              id="nav"
              className={`header__nav ${menuOpen ? '' : 'hidden'}`}
              style={{
                background: isMobile
                  ? `linear-gradient(90deg, ${info?.headerBgColor1} 0%, ${info?.headerBgColor2} 90%)`
                  : '',
              }}
            >
              <ul id="mainmenu" className="ms-2">
                <li>
                  <Link
                    style={{ color: `${info?.fontColor}`, fontWeight: 'bold' }}
                    to="dalk-image"
                    smooth
                    duration={1000}
                  >
                    {i18n.t('menu.inicio')}
                  </Link>
                </li>

                <li>
                  <Link
                    style={{ color: `${info?.fontColor}`, fontWeight: 'bold' }}
                    to="section-about"
                    smooth
                    duration={1000}
                  >
                    {i18n.t('menu.sobre')}
                  </Link>
                </li>

                {access?.show_signup_button && (
                  <li>
                    <Link
                      style={{
                        color: `${info?.fontColor}`,
                        fontWeight: 'bold',
                      }}
                      to="section-register"
                      smooth
                      duration={1000}
                    >
                      {i18n.t('menu.inscrição')}
                    </Link>
                  </li>
                )}

                <li>
                  <Link
                    style={{ color: `${info?.fontColor}`, fontWeight: 'bold' }}
                    to="section-schedule"
                    smooth
                    duration={1000}
                  >
                    Programação
                  </Link>
                </li>

                <li>
                  <Link
                    style={{ color: `${info?.fontColor}`, fontWeight: 'bold' }}
                    to="section-speakers"
                    smooth
                    duration={1000}
                  >
                    {i18n.t('menu.palestrantes')}
                  </Link>
                </li>

                {/* <li>
                  <Link
                    style={{ color: `${info?.fontColor}`, fontWeight: 'bold'}}
                    to="section-bula"
                    smooth
                    duration={1000}
                  >
                    Bula
                  </Link>
                </li> */}

                <li>
                  <a
                    style={{ color: `${info?.fontColor}`, fontWeight: 'bold' }}
                    href={`/${eventKey}/signin`}
                    target="_blank"
                  >
                    {/* {i18n.t('menu.login')} */}
                    Acesse o evento
                  </a>
                </li> 

                {/* Custom Select Dropdown */}
                {/* <li>
                  <div className="custom-select-header">
                    <div className="select-selected" onClick={toggleDropdown}>
                      {language === 'es-cr es-CR' ? (
                        <>
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/assets%2Flanguages%2F6b9d493c-7b38-4a95-9ead-045f65ba7ea2?alt=media&amp;token=04fe8a41-fed0-481d-a66d-d6b60b0a40ee"
                            alt="ES"
                            height="16"
                            style={{ marginRight: '8px' }}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/assets%2Flanguages%2Fa058a92c-7756-47f0-9bb5-c01987a48e0e?alt=media&amp;token=2d365aab-187e-461c-8a10-a3f9ff73050f"
                            alt="PT"
                            height="16"
                            style={{ marginRight: '8px' }}
                          />
                        </>
                      )}
                      {language === 'es-cr es-CR' ? 'ES' : 'PT'}
                      <span className="caret">{dropdownOpen ? '▲' : '▼'}</span>
                    </div>
                    <div
                      className={`select-items ${
                        dropdownOpen ? '' : 'select-hide'
                      }`}
                      style={{ marginTop: '30px' }}
                    >
                      <div
                        className="select-option"
                        onClick={() => {
                          handleSelectchange('pt-BR');
                          setDropdownOpen(false);
                        }}
                        style={{
                          color: 'black',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '2px',
                        }}
                      >
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/assets%2Flanguages%2Fa058a92c-7756-47f0-9bb5-c01987a48e0e?alt=media&amp;token=2d365aab-187e-461c-8a10-a3f9ff73050f"
                          alt="PT"
                          height="16"
                        />
                        PT
                      </div>
                      <div
                        className="select-option"
                        onClick={() => {
                          handleSelectchange('es-cr es-CR');
                          setDropdownOpen(false);
                        }}
                        style={{
                          color: 'black',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '2px',
                        }}
                      >
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/assets%2Flanguages%2F6b9d493c-7b38-4a95-9ead-045f65ba7ea2?alt=media&amp;token=04fe8a41-fed0-481d-a66d-d6b60b0a40ee"
                          alt="ES"
                          height="16"
                        />
                        ES
                      </div>
                    </div>
                  </div>
                </li> */}
              </ul>
            </nav>
            {/* mainmenu close */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
