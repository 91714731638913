import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import NotFound from './components/NotFound';

import Provider from './StoreContext/Provider';

const Routes = () => (
  <Provider>
    <Router>
      <Switch>
        <Route path="/:eventKey" exact component={Home} />
        <Route
          path="/:eventKey/politica-de-privacidade"
          component={PrivacyPolicy}
        />
        <Route path="/:eventKey/termos-condicoes" component={TermsConditions} />

        <NotFound />
      </Switch>
    </Router>
  </Provider>
);

export default Routes;
