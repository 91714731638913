const messages = {
  pt: {
    translations: {
      menu: {
        inicio: 'Início',
        sobre: 'Sobre o evento',
        palestrantes: 'Palestrantes',
        agenda: 'Agenda',
        inscrição: 'Inscreva-se aqui',
        login: 'Login',
      },
      about: {
        textSobre: 'FAZER SEMPRE MELHOR,',
        pAbout1: `
                Dr. Kikuchi¹ liderou com sucesso o desenvolvimento do primeiro agonista parcial dopaminérgico, o antipsicótico aripiprazol (Abilify®²). O medicamento foi aprovado nos Estados Unidos em 2002 e recebeu aprovação na Europa, Japão e muitos outros países para várias indicações clínicas. Em seguida, liderou o desenvolvimento do brexpiprazol (REXULTI®³), uma molécula inovadora, agonista parcial do receptor D2, além de ser um modulador da atividade da serotonina, dopamina e noradrenalina, tendo sua aprovação em 2015 nos Estados Unidos.
                `,
        pAbout12: `Neste evento, teremos a grande oportunidade de receber o Dr. Tetsuro Kikuchi, que esteve à frente de ambos os projetos, liderando e sendo um dos desenvolvedores do aripiprazol e do brexpiprazol. Ele abordará a história da pesquisa e as conquistas na descoberta de medicamentos antipsicóticos para agonistas parciais do receptor de dopamina D2, e as diferenças do brexpiprazol frente ao aripiprazol.`,
        pAbout13: `Após a aula, teremos uma sessão de pergunta e respostas para todos que desejarem interagir com o Dr. Kikuchi. Fiquem atentos ao QR-Code que aparecerá na tela durante a transmissão ao vivo do evento.`,
        pAbout14: `1 - KIKUCHI, Tetsuro. Informações do currículo de Tetsuro Kikuchi. Comunicação pessoal, 02 ago. 2024; 2 - Aristab® Bula do Produto para Profissionais de saúde; 3 - REXULTI® Bula do Produto para Profissionais de saúde.`,
        pAbout2: `
                O Simpósio Estado da Arte em Linfoma Cutâneo reúne especialistas nacionais e internacionais que irão debater as principais tendências, casos clínicos e avanços no tratamento da doença. Uma ótima oportunidade de aprimorar seus conhecimentos para proporcionar mais conforto e qualidade de vida aos seus pacientes.
                `,
      },
      speakers: {
        Speakers: 'Palestrantes ',
        speakers: 'palestras ',
        speaker01: {
          name: `Dr. Otávio Baiocchi `,
          description: `
                        <li>Coordenador da Hematologia e Onco-hematologia no Hospital Alemão Oswaldo Cruz.</li>
                        <li>Professor adjunto em Oncologia na UNIFESP, doutorado e pós-doutorado em linfomas. Pesquisador e orientador do Programa de pós-graduação do Departamento de Oncologia Clínica da UNIFESP com foco em Linfomas e coordenador da pós-graduação de Onco Hematologia – prática clínica em doenças Linfoproliferativas da Faculdade de Educação em Ciências da Saúde do no Hospital Alemão Oswaldo Cruz.</li>
                        <li>Autor e editor de livros sobre linfomas e nutrição em Oncologia.</li>

                    `,
        },
        speaker02: {
          name: `Dr. André Neder Ramires Abdo`,
          description: `
                        <li>Médico hematologista especialista em Linfomas, Linfomas Cutâneos e Histiocitoses</li>
                        <li>Médico assistente e Pesquisador Clínico da Disciplina de Hematologia do Hospital das Clínicas - Faculdade de Medicina da Universidade de São Paulo</li>
                        <li>Coordenador da pós-graduação de Onco Hematologia – prática clínica em doenças Linfoproliferativas da Faculdade de Educação em Ciências da Saúde do Hospital Alemão Oswaldo Cruz.</li>
                    `,
        },
        speaker03: {
          name: `Dr. Philip Bachour`,
          description: `
                        <li>Coordenador e responsável técnico pelo Serviço de Transplante de Medula Óssea do Hospital Alemão Oswaldo Cruz.</li>
                        <li>Médico Hematologista especialista em Hematologia, Hemoterapia e Transplante de Medula Óssea.</li>
                        <li>Coordenador da pós-graduação de Onco Hematologia – prática clínica em doenças Linfoproliferativas da Faculdade de Educação em Ciências da Saúde do no Hospital Alemão Oswaldo Cruz.</li>
                    `,
        },
        speaker04: {
          name: `Dra. Marta Zerga - ARGENTINA`,
          description: `
                        <li>Médica pela Universidade de Buenos Aires e Residência em Hematologia no Instituto Municipal de Hematología do Hospital Ramos Mejía.</li>
                        <li>Desde 2000 é membro da American Society Hematology e desde 2015 membro da European Society of Hematology.</li>
                        <li>Presidente da Sociedade Argentina de Hematologia entre 2015 e 2017.</li>
                        <li>Entre 2012 e 2015 foi Diretora da Carrera Universitária de Médica Hematólogo-SAH e UBA – Buenos Aires.</li>
                        <li>Desde 1998 é Docente Carrera Universitaria de Médico Oncólogo-Sede IAR – Buenos Aires.</li>
                        <li>Desde 2010 é Chefe Clínica de Departamento e Coordenadora do Comitê de Docencia do Instituto de Oncologia Angel H. Roffo. – Buenos Aires.</li>
                        <li>Entre 1983 e 1988 fez parte do Departamento de Hematologia  do Hospital Ramos Mejía – Buenos Aires.</li>

                    `,
        },
        speaker05: {
          name: `Dra. Juliana Pereira`,
          description: `
                        <li>Livre Docência e Doutorado em Medicina (Hematologia) pela Universidade de São Paulo e graduação em Medicina pela Universidade Federal de Uberlândia (1989);</li>
                        <li>Professora Associada da Disciplina de Hematologia e Hemoterapia do Departamento de Clínica Médica da Faculdade de Medicina da Universidade de São Paulo (FM-USP); </li>
                        <li>Coordenadora Médica de Hematologia do Instituto do Câncer do Estado de São Paulo.</li>
                        <li>Responsável Técnica do Laboratório de Imunopatologia do Serviço de Hematologia do Instituto Central do Hospital das Clínicas da FM-USP.</li>

                    `,
        },
        speaker06: {
          name: `José Antonio Sanches, MD, PhD`,
          description: `
                        <li>Graduação em Medicina pela Universidade Estadual Paulista Júlio de Mesquita Filho (1980),</li>
                        <li>Residência Médica em Rádio-oncologia no Hospital do Câncer de São Paulo (1981-1983),</li>
                        <li>Residência Médica em Dermatologia no Hospital das Clínicas da Faculdade de Medicina da Universidade de São Paulo (1985-1986),</li>
                        <li>Mestrado em Medicina (Dermatologia) pela Universidade de São Paulo (1994),</li>
                        <li>Doutorado em Medicina (Dermatologia) pela Universidade de São Paulo (1998) e Livre Docência em Medicina (Dermatologia) pela Universidade de São Paulo (2007).</li>
                        <li>Professor titular da Faculdade de Medicina da Universidade de São Paulo.</li>
                        <li>Experiência na área de Medicina, com ênfase em Dermatologia Clínica, atuando principalmente na área de Oncologia cutânea, paraneoplasias e erupções cutâneas por agentes antineoplásicos.</li>

                    `,
        },
      },
      schedule: {
        day: 'Dia 1',
        schedules01: {
          hora: '18:30 - 18:45',
          name: 'Otávio Baiocchi',
          profission: '(HAOC & UNIFESP)',
          descriptionP: `Abertura`,
        },
        schedules02: {
          hora: '18:45 - 19:10',
          name: `Marta Zerga - ARG `,
          descriptionP: `Linfomas Cutâneos de Células B`,
        },
        schedules03: {
          hora: '19:10 - 19:20',
          name: `Marta Zerga - ARG`,
          descriptionP: `Apresentação Caso Clínico Linfoma B`,
        },
        schedules04: {
          hora: '19:20 - 19:45',
          descriptionP: `
                        Discussão e Perguntas
                    `,
        },
        schedules05: {
          hora: '19:45 - 20:10',
          name: `André Abdo - BRA `,
          profission: '(HAOC  &  USP)',
          descriptionP: `Linfomas Cutâneos – Subtipos Raros`,
        },
        schedules06: {
          hora: '20:10 - 20:20',
          name: `Juliana Pereira - BRA `,
          profission: '(USP)',
          descriptionP: `Apresentação Caso Clínico Linfoma Raro`,
        },
        schedules07: {
          hora: '20:20 - 20:45',
          descriptionP: `
                        Discussão e Perguntas
                    `,
        },
        schedules08: {
          hora: '20:45 - 21:45',
          name: `José Antonio Sanches, MD, PhD - BRA`,
          profission: '(USP)',
          descriptionP: ` Estado da Arte em Micose Fungóide e Síndrome de Sezary (Aula Magna) `,
        },
        schedules09: {
          hora: '21:45 - 22:00',
          name: `Otávio Baiocchi & Philip Bachour `,
          descriptionP: `
                    Encerramento
                    `,
        },
      },
      register: {
        writeYourQuestionHere: 'Escreva sua pergunta aqui...',
        anticipateQuestion:
          'Gostaria de antecipar alguma pergunta ao Dr. Kikuchi e/ou à nossa Área Médica sobre o evento? (Opcional)',
        acceptData:
          'Confirmo que sou médico e as informações fornecidas são verdadeiras.',
        Nopatients:
          'Garanto que não haverá presença de pacientes ou não profissionais da saúde durante a nossa reunião e asseguro que não gravarei esta reunião virtual, uma vez que se trata de um encontro usual de educação médica.',
        notFilm:
          'Asseguro que não gravarei esta reunião virtual, uma vez que se trata de um encontro usual de educação médica.',
        yupNameMin: 'Informe seu Nome Completo*',
        yupNameRequired: 'Preenchimento obrigatório*',

        yupEmailRequired: 'Preenchimento obrigatório*',
        yupEmailFormat: 'Formato de endereço de e-mail inválido',
        yupEmailToLowerCase: 'Email deve ser todo minúsculo',

        yupPhone: 'Preenchimento obrigatório*',

        yupCity: 'Preenchimento obrigatório*',

        yupState: 'Seleção inválida',
        yupStateRequire: 'Preenchimento obrigatório*',

        yupCountry: 'Seleção inválida',
        yupCountryRequire: 'Preenchimento obrigatório*',

        yupSpecialty: 'Preenchimento obrigatório*',

        yupInstitution: 'Preenchimento obrigatório*',

        yupCrm: 'Preenchimento obrigatório*',

        yupCrmUf: 'Preenchimento obrigatório*',

        yupprivacyPolicy: 'É preciso assinar os termos',

        yuptermsConditions: 'É preciso assinar os termos',

        yupsendTerms: 'Preenchimento obrigatório*',
        yupnotFilm: 'Preenchimento obrigatório*',
        yupNopatients: 'Preenchimento obrigatório*',
        yupacceptData: 'Preenchimento obrigatório*',
        yupawish: 'Preenchimento obrigatório*',
        yupProfessionalRegister: 'Preenchimento obrigatório*',
        yupacceptRecord: 'Preenchimento obrigatório*',
        yupacceptPrivacyCompany: 'Preenchimento obrigatório*',

        privacyPolicyRstcom: 'É preciso assinar os termos - Plataforma Online ',

        termsConditionsRstcom:
          'É preciso assinar os termos - Plataforma Online',

        Register: 'Inscrição ',
        register: 'inscrição ',
        placeholderName: 'Nome Completo*',
        placeholderEmail: 'E-mail*',
        placeholderPhone: 'Telefone: (99) 9999-9999*',
        placeholderCity: 'Cidade*',
        placeholderState: 'Estado*',
        placeholderCountry: 'País*',
        placeholderSpecialty: 'Especialidade*',
        placeholderInstitution: 'Instituição*',
        placeholderCrm: 'Número do Conselho',
        placeholderCrmDniRut: 'Registro Profissional (CRM / DNI /RUT)*',
        placeholderCrmUf: 'UF do conselho',
        MessageSucces: 'Cadastro concluído com sucesso ',

        privacyPolicy01: 'Declaro que li e concordo com a ',
        privacyPolicy02: 'Política de Privacidade',

        termsConditions01: 'Declaro que li e concordo com os ',
        termsConditions02: 'Termos e Condições de Uso',

        privacyPolicy01Rstcom: 'Declaro que li e concordo com a ',
        privacyPolicy02Rstcom: 'Política de Privacidade - Plataforma Online',
        privacyPolicy03Rstcom: 'Política de Privacidade RSTCom',
        register: 'Inscreva-se aqui',
        registering: 'Cadastrando..',

        sendTerms:
          'Declaro que li e concordo com a ENVIAR DO TERMO DE PRIVACIDADE LUNDBECK PARA COLOCARMOS AQUI (PENDENTE)',

        termsConditions01Rstcom: 'Declaro que li e concordo com os ',
        termsConditions02Rstcom:
          'Termos e Condições de Uso - Plataforma Online',
        termsConditions03Rstcom: 'Termos e Condições de Uso RSTCom',
        andwith: 'e com a',
        andwith2: 'Declaro que li e concordo com a Politica de Privacidade da ',

        SignUp: 'Inscrever-se',
        SigningUp: 'Cadastrando...',

        subscribed: 'Já sou inscrito',

        wish:
          'Desejo receber por e-mail informações sobre produtos, marcas e serviços oferecidos pela Lundbeck e suas empresas coligadas. A Lundbeck gostaria de te enviar informações que acredita que possam ser relevantes para seu trabalho.',
      },
      video: {
        watchVideo: 'Assista o vídeo da história da Lundbeck',
      },
      footer: {
        footerDescription:
          'Em caso de dúvidas, acione o suporte técnico no dia 27/04 das 12:00 às 22:00 ',
        footerBottom: 'Plataforma de transmissão completa de eventos.',
        problems: 'Problemas com a conexão / áudio?',
        clickHere: 'Clique aqui',
      },
    },
  },
};

export { messages };
