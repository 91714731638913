import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import './SectionAbout.css';

const SectionAbout = ({ theme }) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <section
        id="section-about"
        // className={theme ? 'dark-mode' : 'light-mode'}
      >
        {/* <div class="wm wm-border light  fadeInDown text-align">BemVindo</div> */}
        <div className="container" style={{ marginBottom: '30px' }}>
          {/* <div className="row centerFlex"> */}
          {/* <div className="row align-items-center">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              style={{ marginTop: '44px' }}
            >
              <h1 className="text-left">Evento Kikuchi</h1>

              <div className="text_about_minor mt-4">
                <p className="text-secondary" style={{ textAlign: 'justify' }}>
                  <b style={{ fontSize: '12px', lineHeight: '1px' }}>
                    {i18n.t('about.pAbout1')}
                  </b>
                </p>
              </div>
            </div> */}

          {/* <div className="col-lg-6 mb-sm-30 text-center">
              <div className="de-images">
                <img
                  className="eventokikuchi "
                  data-aos="fade-up"
                  src="https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Feventokikuchi%2Fcustomization%2Flarge-03_Banner_Sobre_Programa_800x533.png?alt=media&token=deb7ea5b-186c-4a34-96de-7961d9cb8ef6"
                  alt=""
                /> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
          <div className="row align-items-center">
            <div
              // className="col-lg-6"
              data-aos="fade-right"
              style={{ paddingTop: '44px', paddingBottom: '44px' }}
            >
              <h1
                className="text-left"
                style={{ color: '#e2ed09', fontWeight: 'bold' }}
              >
                SOBRE O EVENTO
              </h1>

              <div className="text_about_minor mt-4">
                <p className="text-secondary">
                  <b
                    style={{
                      color: '#fff',
                      fontSize: 17,
                      fontWeight: 'normal',
                    }}
                  >
                    A escola é um dos principais espaços de convivência, de
                    aprender a conviver e se relacionar com o outro e de
                    elaborar uma forma de estar no mundo. Construir um espaço
                    escolar que valorize e acolha a diversidade, comprometido
                    com o desenvolvimento de competências cidadãs, com a
                    mediação de conflitos e a promoção dos direitos humanos é
                    parte fundamental da criação de um ambiente favorável à
                    aprendizagem de todos e todas que nele convivem, como
                    estudantes, professores, funcionários e comunidade escolar.
                    <br />
                    <br />
                    Com o objetivo de promover um fórum de discussão sobre a
                    relação entre convivência e aprendizagem no espaço escolar,
                    o Instituto Unibanco promove no dia 12 de novembro de 2024 o{' '}
                    <b>
                      Seminário Internacional “Educação Cidadã e Convivência
                      Democrática”.
                    </b>
                    <br />
                    <br />O evento principal será realizado em São Paulo (SP) e
                    <b> fechado para convidados</b>, mas contará com transmissão
                    ao vivo. Para acompanhá-lo, inscreva-se no formulário
                    abaixo.
                    <br />
                    <br />
                    <b>Evento em rede</b>
                    <br />
                    <br />
                    Visando ampliar e diversificar o debate, serão promovidos
                    simultaneamente encontros híbridos nos estados parceiros do
                    Jovem de Futuro. O programa, implementado em parceria com 6
                    secretarias estaduais de educação, tem como objetivo
                    contribuir para a garantia da aprendizagem e redução das
                    desigualdades educacionais entre os alunos, como
                    consequência de uma gestão educacional orientada para o
                    avanço contínuo da educação pública.
                    <br />
                    <br />
                    Voltados para gestores e técnicos das secretarias de
                    Educação, os eventos locais terão programação híbrida,
                    contemplando os desafios e demandas de cada território.
                  </b>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionAbout;
