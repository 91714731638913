import React, { useContext } from 'react';
import ScrollToTop from '../ScrollToTop';
import { Context } from '../../StoreContext/Context';

const Footer = () => {
  const { clientName, infoFaq, info } = useContext(Context);

  return (
    <>
      {/* footer begin */}
      <footer className="mt-4">
        <div className="container d-flex justify-content-center flex-column align-items-center">
          <p style={{ fontWeight: 700, color: '#000' }}>Help Desk</p>
          <p style={{ textAlign: 'center' }}>
            {/* {infoFaq?.helpdesk_languages?.description?.pt}{' '}
            <span>
              {' '}
              <a
                href={infoFaq?.helpdesk_languages?.link?.pt}
                target="_blank"
                rel="noreferrer"
              >
                <span className="id-color ml-1">
                  {infoFaq?.helpdesk_languages?.data?.pt}
                </span>
              </a>
            </span> */}{' '}
            <span style={{ fontWeight: 500 }}>
              Em caso de dúvidas acione o suporte técnico. <br />
              Horário de funcionamento: Dia 07/06 das 13h00 às 21h00 e dia 08/06
              das 07h00 às 14h00.
            </span>
            <br />
            <a
              href="http://api.whatsapp.com/send?1=pt_BR&phone=5511975513958"
              target="_blank"
              style={{
                fontFamily: 'Arial, Helvetica, sans-serif',
                fontWeight: 500,
                padding: '10px',
                fontSize: '16px',
                color: '#7d7e80',
              }}
            >
              {/* <span
                  style={{
                    textDecoration: 'underline',
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                > */}
              (11) 97551-3958
              {/* </span> */}
            </a>
          </p>
        </div>
        <ScrollToTop />
      </footer>
    </>
  );
};

export default Footer;
