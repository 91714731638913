import React, { useState, useEffect, useContext } from 'react';
import Aos from 'aos';
import SpeakersInfo from '../../components/SpeakersInfo';
import { Context } from '../../StoreContext/Context';
import 'aos/dist/aos.css';
import { i18n } from '../../translate/i18n';
const SectionSpeakers = ({ theme }) => {
  const { speaker } = useContext(Context);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  if (!speaker) null;

  return (
    <>
      {/* section begin */}

      <section id="section-speakers">
        {/* <div className="wm wm-border dark" data-aos="fade-down">
          {i18n.t('speakers.speakers')}
        </div> */}
        <div className="container">
          <div className="col-md-6 offset-md-3 text-center text-dark">
            {/* <h1 data-aos="fade-up">{i18n.t('speakers.Speakers')}</h1>
            <div className="separator2">
              <span className="color">
                <i className="fa fa-square" />
              </span>
            </div> */}
            {/* <div className="spacer-single" /> */}
          </div>
          {/* <div className="clearfix" /> */}
          <h1 className="speakers-title">PALESTRANTES</h1>
          <div className="row justify-content-center speakers-container">
            {speaker?.map((speakers) => (
              <SpeakersInfo
                key={`${speakers.id}${Math.random()}`}
                speaker={speakers}
              />
            ))}
          </div>
          <div className="clearfix" />
        </div>
      </section>
      {/* section close */}
    </>
  );
};

export default SectionSpeakers;
