import { useContext } from 'react';
import { Context } from '../../StoreContext/Context';

import {Helmet} from "react-helmet";

const Seo = ( ) => {
    const { seoInfo, favicon } = useContext(Context);

    return(
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{seoInfo?.name}</title>

                <meta
                    name="description"
                    content={seoInfo.description_languages?.pt}
                />

                {/* favicon */}
                <link  rel="shortcut icon"  href={favicon} type="image/x-icon"
                />
            </Helmet>
        </div>
    );
}

export default Seo;
