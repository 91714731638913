import React, { useEffect, useMemo } from 'react';
import './styles.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

const SectionSchedule = ({ theme }) => {
  const datas = [
    {
      id: '1',
      title: 'PROGRAMAÇÃO',
      speakers: [
        {
          id: '1',
          hora: '09h00 às 09h30',
          name: 'Ricardo Henriques, Instituto Unibanco',
          descriptionP: 'Abertura',
        },
        {
          id: '3',
          hora: '09:30 às 11h00',
          name: 'Cristián Cox, Universidad Diego Portales (Chile)',
          descriptionP:
            'Palestra Magna: Educação Cidadã em perspectiva global​',
        },
        {
          id: '4',
          hora: '11h00 às 12h30',
          name: 'Clara Ramirez-Barat, Instituto Auschwitz',
          descriptionP:
            '<b>Painel 1</b><br/><b>Experiências Nacionais de Educação para a Cidadania</b><br/><i>Como as escolas podem promover convivência democrática e educação cidadã em tempos de polarização e desafios sociais?</i><br/>Caio Callegari, Instituto Unibanco<br/>',
            // 'Painel 1: Experiências nacionais de educação para a cidadania',
        },
        {
          id: '5',
          hora: '12h30 às 14h00',
          name: 'Almoço',
          descriptionP: '',
        },
        {
          id: '6',
          hora: '14h00 às 15h30',
          name: `Maria Beatriz Bonna Nogueira, ACNUR Brasil<br/>Ana Carolina Evangelista, ISER`,
          descriptionP:
            '<b>Painel 2</b><br/><b>Diversidade Cultural e Religiosa na Vida Escolar</b><br/><i>Como promover o respeito pelas diferenças, prevenir discriminações e criar um clima escolar favorável à convivência democrática e ao desenvolvimento integral dos educandos?</i>',
            // 'Painel 2: Diversidade cultural e religiosa no cotidiano das escolas',
        },
        {
          id: '7',
          hora: '15h30 às 17h00',
          name: '',
          // name: 'Maria Victoria Benevides, Comissão Arns',
          descriptionP:
            '<b>Painel 3</b><br/><b>Educação e Direitos Humanos em meio aos ataques à democracia</b><br/><i>Como as escolas podem agir como espaços de resistência ao autoritarismo e de enfrentamento às ameaças à democracia?</i><br/>Augustin Holl, Universidade de Xiamen<br/>Lidiane Grutzmann, Universidade Alto Vale do Rio do Peixe (SC)',
            // 'Painel 3: Educação e Direitos Humanos em meio aos ataques à democracia',
        },
      ],
    },
  ];

  function Tab({ data }) {
    return (
      <div className="schedule-container text-center">
        {data.map((item) => (
          <div key={item.id} className="schedule-section">
            <h1 className="schedule-title">{item.title}</h1>
            <div className="schedule-events">
              {item.speakers.map((speaker) => (
                <div key={speaker.id} className="schedule-event">
                  {/* <div className="event-time">{speaker.hora}</div> */}
                  <div className="event-details">
                    <div className="event-time">{speaker.hora}</div>
                    {/* <span className="event-title">{speaker.descriptionP}</span> */}
                    <span
                      className="event-speaker"
                      dangerouslySetInnerHTML={{
                        __html: speaker.descriptionP.replace(/\n/g, '<br />'),
                      }}
                    ></span>
                    <span
                      className="event-speaker"
                      dangerouslySetInnerHTML={{
                        __html: speaker.name.replace(/\n/g, '<br />'),
                      }}
                    ></span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <section
        id="section-schedule"
        // className={theme ? 'dark-mode' : 'light-mode'}
        aria-label="section-services-tab"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Tab data={datas} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionSchedule;
