import { useContext } from 'react';
import { Context } from '../../StoreContext/Context';
import { Link } from 'react-scroll';
import './BannerParallax.css';

const BannerParallax = () => {
  const { info } = useContext(Context);
  return (
    <>
      {/* parallax section */}
      <div className="dalk-image">
        <Link to="section-register" smooth duration={1000}>
          <img
            className="desktop-image"
            src={info?.bannerlp}
            alt="Imagem Banner"
            style={{ cursor: 'pointer' }}
          />
        </Link>
      </div>
      {/* section close */}
    </>
  );
};

export default BannerParallax;
