import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import Seo from '../../components/Seo';
import Header from '../../components/Header';
import AlertBrowser from '../../components/AlertBrowser';
import BannerParallax from '../../components/BannerParallax';
import SectionCountdown from '../../Sections/SectionCountdown';
import SectionAbout from '../../Sections/SectionAbout';
import SectionSpeakers from '../../Sections/SectionSpeakers';
import SectionSchedule from '../../Sections/SectionSchedule';
import SectionRegister from '../../Sections/SectionRegister';
import SectionBula from '../../Sections/SectionBula';
// import SectionHelpDesk from '../../Sections/SectionHelpDesk';
import Footer from '../../components/Footer';
import FooterBottom from '../../components/FooterBottom';
import LoadingScreen from '../../components/LoadingScreen';

import { Context } from '../../StoreContext/Context';
import SectionVideo from '../../Sections/SectionVideo';

const Home = () => {
  const { loading, loadEvent, config } = useContext(Context);
  const { eventKey } = useParams();

  useEffect(() => {
    loadEvent(eventKey);
  }, []);
  console.log('config', config);

  return (
    <>
      <Seo />
      {loading === false ? (
        <div>
          <div id="wrapper">
            <Header />

            <AlertBrowser />

            <div id="content" className="no-bottom no-top">
              <BannerParallax />

              {/* <SectionCountdown /> */}

              <SectionAbout />

              {/* <SectionBula /> */}

              <SectionRegister />

              <SectionSchedule />

              <SectionSpeakers />

              {/* <SectionVideo /> */}

              {/* <SectionHelpDesk/> */}

              {/* <Footer /> */}
            </div>
          </div>

          <FooterBottom />
        </div>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default Home;
